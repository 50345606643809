<template>
    <bread-page>
        <div class="main" v-loading="loading">
            <div class="top">
                <div class="info">
                    <div class="info-item">
                        <span class="text1">拟融资额：</span>
                        <span class="text2">{{ info.amount }}万</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">融资目的：</span>
                        <span class="text2">扩大规模</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">联系人：</span>
                        <span class="text2">{{ info.contact }}</span>
                    </div>
                    <div class="info-item">
                        <span class="text1">期望贷款期限：</span>
                        <span class="text2">{{ loanTerm }}</span>
                    </div>

                    <!-- <div class="info-item">
                    <span class="text1">电话：</span>
                    <span class="text2">{{ info.phone }}</span>
                </div> -->
                    <div class="info-item">
                        <span class="text1">所在地区：</span>
                        <span class="text2">{{ info.address }}</span>
                    </div>
                </div>
                <div class="time">
                    {{ formatTime(info.createdAt, 'YYYY.MM.DD') }}
                </div>
            </div>
            <div>
                <div class="btn">
                    <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small">
                        <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                        咨询客服
                    </el-button>
                    <el-button
                        @click.stop="postMessage"
                        type="primary"
                        class="icon-button"
                        
                        size="small"
                    >
                        <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
                    </el-button>
                </div>
            </div>
        </div>
    </bread-page>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
import { purchaseTypeOptions, tradingMethodOptions } from '../../utils/variables';
import comEvent from '../../mixins/comEvent';
export default {
    components: { BreadPage },
    mixins: [comEvent],
    data() {
        return {
            loading: true,
            info: {},
            fieldOptions: [],
            purchaseTypeOptions,
            tradingMethodOptions
        };
    },
    computed: {
        imgs() {
            if (this.info.img && this.info.img.length > 0) {
                return this.info.img;
            } else {
                return [this.getImg()];
            }
        },
        purchaseType() {
            let info = purchaseTypeOptions.find(item => {
                return item.value === this.info.purchaseType;
            });
            return info ? info.label : '';
        }
    },
    mounted() {
        this.loading = true;
        this.$http.get('/funding/get/' + this.$route.query.id).then(res => {
            // console.log(res);
            this.info = res;
            setTimeout(() => {
                this.loading = false;
            }, 500);
        });
    }
};
</script>

<style lang="less" scoped>
.main {
    background-color: #fff;
    padding: 22px 100px 150px;
}

.info {
    .flex();
    flex-wrap: wrap;
    .info-item {
        padding-right: 30px;
        box-sizing: border-box;
        line-height: 32px;
        width: 33%;
        .flex();

        .text1 {
            font-size: 13px;
            color: #939599;
            display: inline-block;
            flex-shrink: 0;
        }

        .text2 {
            font-size: 13px;
            color: #00684f;
            margin-left: 10px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
</style>
